import _ from 'underscore';
import IMOG from '~/lib/imog';
import useSpring from '~/lib/imog/use/spring';
import useWindowSize from '~/lib/imog/use/windowSize';
import Paper from 'paper';
import { gsap } from 'gsap';

import Logo from '~/component/Logo';
import Header from './Header';

const urlParams = new URLSearchParams(window.location.search);

export default IMOG.Component('Landing', {
  options: {
    handleReady: () => {},
    handleItemLoaded: () => {},
    domElement: document.querySelector('.logoViewer canvas'),
  },

  props() {
    return {
      randomAspect: false,

      windowSize: useWindowSize(),

      marginTop: (props) => (props.windowSize.width < 900 ? 24 : 40),
      margin: (props) => (props.windowSize.width < 900 ? 12 : 24),

      logoWidth: (props) => props.windowSize.width - props.margin * 2,
      logoHeight: (props) =>
        props.windowSize.height - props.margin - props.marginTop,
      logoSize: (props) => ({
        width: props.logoWidth,
        height: props.logoHeight,
        marginTop: props.marginTop,
        margin: props.margin,
      }),
      logoVisible: true,
    };
  },

  setup({ options }) {
    window.canvas = this;

    this.paper = new Paper.PaperScope();
    this.paper.setup(options.domElement);
    this.paper.settings.insertItems = false;

    this.logo = new Logo({
      options: {
        paper: this.paper,
        addTo: this.paper.project.activeLayer,
      },
      props: {
        size: (props) => this.props.logoSize,
        visible: (props) => this.props.logoVisible,
      },
    });
    this.logo.props.c = _.range(1, 6).map((i) => ({ block: i, amount: 0 }));

    this.logo.blocks.props.gridVisible = false;
    this.logo.guides.props.variablesVisible = false;

    this.header = new Header({
      props: {
        value: (props) => this.logo.props.code,
      },
    });

    this.tween;
    options.domElement.addEventListener(
      'click',
      _.throttle(() => {
        let wasActive = false;
        if (this.tween) {
          wasActive = this.tween.active;
        }
        if (this.tl) {
          this.tl.kill();
        }
        const d = wasActive ? 0.5 : 0.8;
        const ease = wasActive ? 'power2.out' : 'power4.inOut';
        const c = this.logo.generateCode({
          maxOverlaps: wasActive ? 0 : undefined,
        });
        this.tween = this.logo.animateToCode(c, {
          duration: d,
          ease,
          onComplete: this.startIdle({ startAt: 8 }),
        });
      }, 200)
    );

    document.querySelector('.code').addEventListener('dblclick', () => {
      window.open(`/editor.html?dev&code=${this.logo.props.code}`, '_blank');
    });

    const infoSectionEl = document.querySelector('.layout.info');
    let infoVisible = false;
    const showInfoSection = () => {
      if (infoVisible) return;
      infoVisible = true;
      gsap.killTweensOf(infoSectionEl);
      infoSectionEl.style.pointerEvents = 'all';
      gsap.fromTo(
        infoSectionEl,
        { opacity: 0 },
        {
          opacity: 1,
          ease: 'sine.out',
          duration: 0.4,
          onComplete: () => {},
        }
      );
    };
    const hideInfoSection = () => {
      if (!infoVisible) return;
      infoVisible = false;
      gsap.killTweensOf(infoSectionEl);
      gsap.fromTo(
        infoSectionEl,
        { opacity: 1 },
        {
          opacity: 0,
          ease: 'sine.out',
          duration: 0.4,
          onComplete: () => {
            infoSectionEl.style.pointerEvents = 'none';
          },
        }
      );
    };

    document.querySelector('.info-link').addEventListener('click', () => {
      showInfoSection();
    });
    [...document.querySelectorAll('.title, .info')].forEach((el, i) => {
      el.addEventListener('click', () => {
        if (infoVisible) hideInfoSection();
        else showInfoSection();
      });
    });
    [...document.querySelectorAll('p, a')].forEach((el, i) => {
      el.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    });

    this.startIdle();

    options.handleReady();
    [...document.querySelectorAll('.layout, .header')].forEach((el, i) => {
      el.style.display = '';
    });
  },

  methods: {
    startIdle({ startAt = 0.6, duration = 7 } = {}) {
      const tl = (this.tl = gsap.timeline({ repeat: -1 }));
      const d = 1.2;
      const ease = 'power4.inOut';

      tl.add(() => {
        const c = this.logo.generateCode();
        this.logo.animateToCode(c, { duration: d, ease });

        if (this.props.randomAspect) {
          gsap.to(this.props, {
            duration: d,
            ease: ease,
            logoWidth: _.sample([1, 1, 1, 1, 2, 2, 4]) * this.props.logoHeight,
          });
        }
      }, startAt).add(() => {}, duration);
    },
  },

  hooks: {
    'set:logoSize'({ width, height, marginTop, margin }) {
      if (width === 0 || height === 0) return;
      this.paper.view.setCenter(
        width / 2,
        height / 2 - marginTop / 2 + margin / 2
      );
    },
  },
});
