import IMOG from '~/lib/imog';
import _ from 'underscore';

export default IMOG.Component('Code', {
  props() {
    return {
      value: 'MF',
    };
  },

  setup({ options }) {
    this.codeEl = document.querySelector('.code');
  },

  hooks: {
    'set:value'(v) {
      this.codeEl.innerText = v;
    },
  },
});
