console.log('%c Manifold Landing ', 'background: #ccc; color: #0000ff');
console.log('%c ● Coded by XL Digital ', 'background: #ccc; color: #0000ff');

import Canvas from '~/component/Landing';

window.canvas = new Canvas({
  options: {
    handleReady: () => {},
  },
});
